const columns = [
    { name: 'Courses', uid: 'courseName' },
    { name: 'Day - Indian Standard Time (IST)', uid: 'day' },
    { name: 'Status', uid: 'status' },
    { name: 'QRCode', uid: 'qrCodeLink' },
    { name: 'Whatsapp', uid: 'whatsappLink' },
];

const users = [
    {
        id: 1,
        courseName: 'Morning Japa Group',
        time: '5:00am to 7:00am',
        day: 'Everyday',
        status: 'active',
        avatar: 'harinamCintamani.jpg',
        qrCodeLink: 'https://drive.google.com/file/d/1Xf2x-gDlTiACwWR1F-_AOFZVBZvWaMKy/view?usp=drive_link',
        whatsappLink: 'https://chat.whatsapp.com/IrGuIZK80npFZP4OeoR1GD',
    },
    {
        id: 2,
        courseName: 'Śrīmad-Bhāgavatam Study Group',
        time: '7:15am to 8:15am',
        day: 'Weekdays (Monday to Friday)',
        status: 'active',
        avatar: 'srimadBhagavatam.jpg',
        qrCodeLink: 'https://drive.google.com/file/d/143hClnfnMnbO9_AcGMHB_Ylp3X1qKfmT/view?usp=drive_link',
        whatsappLink: 'https://chat.whatsapp.com/I92aGgP5pWcDlc3cpE7mWQ',
    },
    {
        id: 3,
        courseName: 'Bhakti Sandarbha',
        time: '12:00PM to 2:00PM',
        day: 'Monday & Wednesday',
        status: 'active',
        avatar: 'sadSandharbas.jpg',
        qrCodeLink: 'https://drive.google.com/file/d/1hS-Fur0PBPqE4MJBUxomC4K_0_1e30Bp/view?usp=drive_link',
        whatsappLink: 'https://chat.whatsapp.com/BYFxXrnBC1vBytkj9ymkmT',
    },
    {
        id: 4,
        courseName: 'Jaiva Dharma',
        time: '8:00PM to 9:30PM',
        day: 'Monday & Tuesday',
        status: 'active',
        avatar: 'jaivadharma.jpg',
        qrCodeLink: 'https://drive.google.com/file/d/1_cKnDXHlqYHz4Vh3N-S6S6Z-QuRJrCN_/view?usp=drive_link',
        whatsappLink: 'https://chat.whatsapp.com/BnED5QuE7e5AynBpJpcOlZ',
    },
    {
        id: 5,
        courseName: 'Śrīmad-Bhāgavatam (Canto 10)',
        time: '12:00PM to 2:00PM',
        day: 'Tuesday & Thursday',
        status: 'active',
        avatar: 'srimadBhagavatam.jpg',
        qrCodeLink: 'https://drive.google.com/file/d/1Y4iMDvSHLMSan0SJTgIlCETPcOOeTT2X/view?usp=drive_link',
        whatsappLink: 'https://chat.whatsapp.com/Ih76MBp37B88YzW20kXM4A',
    },
    {
        id: 7,
        courseName: 'Qualification to Hear Krsna\'s Pastimes',
        time: '8:00PM to 9:30PM',
        day: 'Thursday',
        status: 'active',
        avatar: 'bhaktiTattvaViveka.jpg',
        qrCodeLink: 'https://drive.google.com/file/d/1gPz_I1y__Efe3ssl8_v9S6-A7dDjKfRO/view?usp=sharing',
        whatsappLink: 'https://chat.whatsapp.com/IlSATsch5jj6fWcxEOL0Hh',
    },
    {
        id: 8,
        courseName: 'Bhakti Rasāmrta Sindhu: Southern Section',
        time: '12:00PM to 2:00PM',
        day: 'Friday',
        status: 'active',
        avatar: 'brs.jpg',
        qrCodeLink: 'https://drive.google.com/file/d/1kQtgiKeIo_qnn_zJh0OrKf56sQS5IrAQ/view?usp=drive_link',
        whatsappLink: 'https://chat.whatsapp.com/HxqYF52H26rEqJb41yrd8q',
    },
    {
        id: 9,
        courseName: 'Śrīmad Bhagavad-gītā',
        time: '8:00PM to 9:30PM',
        day: 'Friday',
        status: 'active',
        avatar: 'bhagavadGita.jpg',
        qrCodeLink: 'https://drive.google.com/file/d/13lmL8w1YBYq19hqVJXIO3BQv_YfJ8xM1/view?usp=drive_link',
        whatsappLink: 'https://chat.whatsapp.com/GvK7se7u2KXDZINAhMmIP4',
    },
    {
        id: 10,
        courseName: 'Śrī Kṛṣṇa smaraṇa sphūrti',
        time: '7:00PM to 9:00PM',
        day: 'Wednesday',
        status: 'active',
        avatar: 'krsnaSmaranaSphurti.jpg',
        qrCodeLink: 'https://drive.google.com/file/d/1JY1F0qGQ5jyFQ2_L8yt3wbRWVPGzeAZG/view?usp=drive_link',
        whatsappLink: 'https://chat.whatsapp.com/L67sDcZn4J1EAq03OWKUgP',
    },
];

export { columns, users };