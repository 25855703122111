import React, { useEffect } from 'react';
import { Card, CardHeader, CardBody, Spacer, Divider, Link } from '@nextui-org/react';
import { motion } from 'framer-motion';

const PrivacyPolicy = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 250)

    }, [])
    return (
        <main className='pb-10 sm:pb-0'>
            <div className={'grid-rows-schedule-row grid-cols-schedule-column grid xs:h-full sm:h-screen sm:grid-cols-8 sm:mt-0'}>
                <div className={'col-span-full bg-privacy brightness-50 bg-fixed bg-center xs:h-full bg-cover bg-no-repeat  sm:block hidden opacity-90 row-span-full'}/>
                <motion.div className={'col-start-2 col-end-8 xl:col-start-3 xl:col-end-7 opacity-[97%] xs:row-end-auto row-start-2 sm:row-end-span-2 h-[calc(100%_-_10rem)]'}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 0.8 }}
                            transition={{
                                duration: 0.5,
                                ease: "easeOut",
                            }}
                >
                    <Card className={'grid grid-cols-8'}>
                        <CardHeader className='pb-0 pt-2 px-4 flex-col items-center col-span-full'>
                            <h1 className='font-extrabold sm:text-3xl text-2xl mb-2 text-red dark:text-lightGrey mt-2'>Privacy & Copyright Notice</h1>
                        </CardHeader>
                        <CardBody className={'col-span-full'}>
                            <div>
                                <li>All materials hosted on this site are based on books which are the copyrighted property of their respective publishers and are used on this site solely for educational purpose.</li>
                                <Spacer y={1}/>
                                <li>Where required, special permissions have been granted by publishers for use of their work on this site.</li>
                                <Spacer y={1}/>

                                <li>Unauthorized use by anyone else is strictly prohibited.</li>
                                <Spacer y={1}/>

                                <li>No part of any published works available at this site may be reproduced, distributed, or transmitted in any form or by any means, including photocopying, recording, or other electronic or mechanical methods, without the prior written permission of the publisher, except for non-commercial uses permitted by copyright law.</li>
                                <Spacer y={1}/>
                                <li>This site is entirely non-commercial in nature and while devotees may use the educational contents shared for their personal use, it is intended that the contents of this site should not be used for any commercial purposes or with monetization in mind.</li>
                                <Spacer y={4}/>
                            </div>
                            <Divider/>
                            <Spacer y={1}/>
                            <CardHeader className='pb-0 pt-2 px-4 flex-col items-center col-span-full'>
                                <h1 className='font-extrabold sm:text-3xl text-2xl mb-2 text-red dark:text-lightGrey mt-2'>Request from Bhakti Grantha Sevā</h1>
                            </CardHeader>
                            <Spacer y={2}/>
                            <p>
                                Users of Bhakti Grantha Sevā are sincerely encouraged to support the original publishers by purchasing their books directly from their online bookstores by clicking the link below.
                            </p>
                            <Spacer y={2}/>
                            <Link
                                isExternal
                                href='https://www.amazon.in/Books-HH-Bhanu-Swami/s?rh=n%3A976389031%2Cp_27%3AHH+Bhanu+Swami'
                                className={'inline z-50'}
                            >
                                Books by His Holiness Bhānu Swāmi Mahārāja
                            </Link>
                            <Spacer y={2}/>
                            <Link
                                isExternal
                                href='https://srsbooks.com/collections/books'
                                className={'inline z-50'}
                            >
                                Books by His Holiness Śivarāma Swāmi Mahārāja
                            </Link>
                            <Spacer y={2}/>
                            <Link
                                isExternal
                                href='https://a.co/d/2rVieCz'
                                className={'inline z-50'}
                            >
                                Śrī Manaḥ-Śikṣā: Splendid Instructions to the Mind
                            </Link>
                            <Spacer y={2}/>
                            <Link
                                isExternal
                                href='https://krishnastore.com.au/books-srila-prabhupada-om-21_38.html '
                                className={'inline z-50'}
                            >
                                Books by His Divine Grace A. C. Bhaktivedānta Swāmi Śrīla Prabhupāda
                            </Link>
                            <Spacer y={4}/>
                            {/*<Divider/>*/}
                            {/*<Spacer y={2}/>*/}
                        </CardBody>
                    </Card>
                </motion.div>
            </div>
        </main>
    )
};

export default PrivacyPolicy;
