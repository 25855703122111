import React from 'react';

const About = () => {
    return (
        <div className={'flex justify-center h-screen items-center font-extrabold'}>
            About page is being built.
        </div>
    );
};

export default About;
