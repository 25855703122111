import React, { useEffect } from "react";
import { useTheme } from 'next-themes';
import { SunIcon } from './SunIcon';
import { MoonIcon } from './MoonIcon';

export default function DarkMode() {
    const { setTheme } = useTheme();
    // const currentTheme = theme === 'system' ? systemTheme : theme;
    const [isSelected, setIsSelected] = React.useState(true);
    useEffect(() => {
        if (isSelected) {
            setTheme('light')
        } else {
            setTheme('dark')

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelected]);
    return (

        <div className={'flex justify-center items-center w-8 h-8 bg-darkBlue hover:bg-darkBlue/60 rounded-md dark:bg-lightGrey dark:hover:bg-lightGrey/50 cursor-pointer'} onClick={() => setIsSelected(!isSelected)}>
            {isSelected && <SunIcon/>}
            {!isSelected && <MoonIcon/>}
        </div>
    )
}
