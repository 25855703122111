import { createContext, useReducer } from 'react';

// @ts-ignore
export const BhaktiContext = createContext();

export const snippetReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'GET_COURSES':
            return { ...state, courses: action.payload };
    }
};

// @ts-ignore
export const BhaktiContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(snippetReducer, {
        snippets: [],
    });

    return (
        <BhaktiContext.Provider value={{ ...state, dispatch }}>
            {children}
        </BhaktiContext.Provider>
    );

};