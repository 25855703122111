import React from "react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, User, Chip, Tooltip, ChipProps, Link } from "@nextui-org/react";
import { columns, users } from "./data";
import { useTheme } from 'next-themes';
import { ReactComponent as Whatsapp } from '../images/whatsapp.svg'
import { ReactComponent as WhatsappDark } from '../images/whatsapp-dark.svg'
import { motion } from "framer-motion";

const statusColorMap: Record<string, ChipProps["color"]> = {
    active: "success",
    paused: "danger",
    scheduled: "warning",
};

type UserType = typeof users[0];

export default function ScheduleTable() {
    const { theme } = useTheme();

    const renderCell = (user: UserType, columnKey: React.Key) => {
        const cellValue = user[columnKey as keyof UserType];
        switch (columnKey) {
            case "courseName":
                return (
                    <>
                        <div className={'hidden lg:block'}>
                            <User
                                // avatarProps={{ radius: "lg", src: require(`../images/Areyousure.jpg`) as string }}
                                avatarProps={{ radius: "lg", src: require(`../images/${user.avatar}`) }}
                                // description={user.courseName}
                                name={cellValue}
                                classNames={{
                                    name: [
                                        "font-extrabold",
                                        "text-darkBlue",
                                        "dark:text-lightBlue",
                                        "text-[16px]"
                                    ],
                                }}
                            >
                                {/*{user.time}*/}
                            </User>
                        </div>
                        <p className={'font-bold lg:hidden block'}>{user.courseName}</p></>
                );
            case "day":
                return (
                    <div className='flex flex-col'>
                        <p className='font-bold text-[15px] capitalize text-lightOrange dark:text-lightBlue'>{cellValue}</p>
                        <p className='text-[14px] capitalize text-darkBlue dark:text-lightGrey'>{user.time}</p>
                    </div>
                );
            case "status":
                return (
                    <Chip className='capitalize text-md' color={statusColorMap[user.status]} size='sm' variant='flat'>
                        {cellValue}
                    </Chip>
                );
            case "qrCodeLink":
                return (
                    <div className='relative flex items-center gap-2'>
                        <Tooltip content='click here to join using QR code'>
                            <Link
                                isBlock
                                showAnchorIcon
                                href={`${user.qrCodeLink}`}
                                color='primary'
                                isExternal/>
                        </Tooltip>
                    </div>
                );
            case "whatsappLink":
                return (
                    <div className='relative flex items-center gap-2'>
                        <Tooltip content='Click here to join whatsapp group'>
                            <Link
                                isBlock
                                // showAnchorIcon
                                href={`${user.whatsappLink}`}
                                color='primary'
                                className={'py-1 px-1 cursor-pointer mt-0.5'}
                                isExternal

                            >
                                {theme === 'light' ? <Whatsapp/> : <WhatsappDark/>}
                            </Link>
                        </Tooltip>
                    </div>
                );
            default:
                return cellValue;
        }
    }
    const classNames = React.useMemo(
        () => ({
            th: ["text-[16px]"],
        }),
        [],
    );
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                duration: 0.5,
                ease: "easeOut",
            }}
        >
            <Table aria-label='Example table with custom cells' fullWidth={true} className={'opacity-95'} classNames={classNames}>
                <TableHeader columns={columns}>
                    {(column) => (
                        <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody items={users}>
                    {(item) => (
                        <TableRow key={item.id}>
                            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </motion.div>
    );
}

