import React, { useEffect } from 'react';
import { Card, CardHeader, CardBody, Divider, Spacer, Button, CardFooter, Link } from "@nextui-org/react";
import { useTheme } from 'next-themes';
import { useNavigate, useLocation, } from 'react-router-dom';
import { ReactComponent as MobileSP } from '../images/mobileSP.svg'
import { ReactComponent as MobileDarkSP } from '../images/mobileDarkSP.svg'
import queryString from 'query-string';
import { motion } from 'framer-motion';
import AnimatedTextWord from './AnimatedTextWord';

const LandingPage = () => {
    const { theme } = useTheme();
    const navigate = useNavigate()
    const location = useLocation();
    const onAcceptTerms = () => {
        sessionStorage.setItem("accepted", "Yes");
        const { redirectTo } = queryString.parse(location.search);
        navigate(`${redirectTo === null ? "/courses" : redirectTo}`)
    }
    const areTermsAccepted = sessionStorage.getItem("accepted");

    useEffect(() => {
        if (areTermsAccepted === 'Yes') {
            navigate('/courses')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areTermsAccepted]);

    return (
        <motion.div className={'flex justify-center items-center sm:py-7 py-10 mx-3.5'}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 0.75,
                        ease: "easeOut",
                    }}>
            <Card className='max-w-[98%] border-1.5 border-solid sm:before:w-[10%] sm:before:h-[10%] before:-left-[0px] before:-bottom-[0px] before:border-l-3 before:border-l-lightOrange before:border-solid before:absolute before:content-[""] before:border-b-3 before:border-b-lightOrange before:rounded-bl-large  sm:after:w-[10%] sm:after:h-[10%] after:-top-[0px] after:-right-[0px] after:border-t-3 after:border-t-lightOrange after:border-solid after:absolute after:content-[""] after:border-r-3 after:border-r-lightOrange after:rounded-tr-large sm:hover:before:w-[100%] sm:hover:before:h-[100%] sm:hover:after:w-[100%] sm:hover:after:h-[100%] before:transition-all before:duration-250 after:transition-all after:duration-250
            hover:before:rounded-tl-large hover:before:rounded-tr-large hover:before:rounded-br-large hover:after:rounded-tl-large hover:after:rounded-bl-large hover:after:rounded-br-large'>
                <CardHeader className='flex gap-3'>
                    <div className={'cursor-pointer'}>
                        {theme === 'light' ? <MobileSP/> : <MobileDarkSP/>}
                    </div>
                    <div className='flex flex-col'>
                        {/*<p className='sm:text-3xl text-2xl text-center text-red dark:text-lightGrey font-extrabold'>Bhakti Grantha Sevā</p>*/}
                        <AnimatedTextWord text={'Bhakti Grantha Sevā'}/>
                    </div>
                </CardHeader>
                <Divider/>
                <CardBody>
                    <h2 className={'text-lg font-bold text-red'}>
                        About:
                    </h2>
                    <Spacer y={1}/>
                    <p>Bhakti Grantha Seva portal is a humble offering to the devotees of Lord Caitanya who have developed deep understanding and appreciation for the teachings of <Link
                        isExternal
                        href='https://en.wikipedia.org/wiki/A._C._Bhaktivedanta_Swami_Prabhupada'
                        className={'inline z-50'}
                    >
                        His Divine Grace A. C. Bhaktivedānta Swāmi Prabhupāda
                    </Link>, the Founder-Ācārya of the <Link
                        isExternal
                        href='https://en.wikipedia.org/wiki/International_Society_for_Krishna_Consciousness'
                        className={'inline z-50'}
                    >
                        International Society for Krishna Consciousness
                    </Link>. </p>
                    <Spacer y={3}/>
                    <p>The website contains courses based on the books of great ācāryas in the Gauḍīya-sampradāya like <Link
                        isExternal
                        href='https://en.wikipedia.org/wiki/A._C._Bhaktivedanta_Swami_Prabhupada'
                        className={'inline z-50'}
                    >
                        Śrīla Prabhupāda
                    </Link>, <Link
                        isExternal
                        href='https://en.wikipedia.org/wiki/Bhaktivinoda_Thakur'
                        className={'inline z-50'}
                    >
                        Śrīla Bhaktivinoda Ṭhākura
                    </Link>, <Link
                        isExternal
                        href='https://en.wikipedia.org/wiki/Visvanatha_Chakravarti'
                        className={'inline z-50'}
                    >
                        Śrīla Viśvanātha Cakravartī Ṭhākura
                    </Link>, <Link
                        isExternal
                        href='https://en.wikipedia.org/wiki/Jiva_Goswami'
                        className={'inline z-50'}
                    >
                        Śrīla Jīva Goswami
                    </Link>, <Link
                        isExternal
                        href='https://en.wikipedia.org/wiki/Rupa_Goswami'
                        className={'inline z-50'}
                    >
                        Śrīla Rūpa Goswāmī
                    </Link> and contemporary ācāryas like <Link
                        isExternal
                        href='https://sivaramaswami.com/'
                        className={'inline z-50'}
                    >
                        Śrīla Śivarāma Swāmi Mahārāja
                    </Link> to name a few.
                    </p>
                    <Spacer y={3}/>
                    <p>The courses are presented in the form of ‘reflective reading sessions’ where these works are read and discussed elaborately. The facilitator and the audience engage in thorough churning of the subject matter to deepen their appreciation. </p>
                    <Spacer y={3}/>
                    <p>We wish to express our deep gratitude to <Link
                        isExternal
                        href='https://www.bhanuswamivanimedia.com/'
                        className={'inline z-50'}
                    >
                        His Holiness Bhānu Swāmi Mahārāja
                    </Link> for his meticulous translation works on <Link
                        isExternal
                        href='https://www.amazon.in/Books-HH-Bhanu-Swami/s?rh=n%3A976389031%2Cp_27%3AHH+Bhanu+Swami'
                        className={'inline z-50'}
                    >
                        Gauḍīya Vaiṣṇava literatures
                    </Link>, which form the foundation of much of our course content.
                    </p>
                    <Spacer y={3}/>
                    <Divider/>
                    <Spacer y={3}/>
                    <h2 className={'text-lg font-bold text-red'}>
                        Course Materials Supplied:
                    </h2>
                    <Spacer y={1}/>
                    <p>For each course, every session includes:</p>
                    <Spacer y={1}/>
                    <div>
                        <li className={'ml-3'}>A YouTube video for an in-class visual learning experience,</li>
                        <li className={'ml-3'}>An MP3 audio file for listening on-the-go,</li>
                        <li className={'ml-3'}>PDF notes with session slides and class notes discussed.</li>
                        <li className={'ml-3'}>All materials hosted on this site are based on books which are the copyrighted property of their respective publishers and are used on this site solely for educational purpose. Unauthorized use by anyone is strictly prohibited.</li>
                    </div>
                    <Spacer y={3}/>
                    <Divider/>
                    <Spacer y={3}/>

                    <h2 className={'text-lg font-bold text-red'}>
                        Live Sessions:
                    </h2>
                    <Spacer y={1}/>
                    <p>Apart from the above-mentioned pre-recorded courses, there are live courses that are currently running and can be joined.</p>
                    <Spacer y={2}/>
                    <p>Click the <span className={'font-extrabold text-red dark:text-lightGrey'}>Live Zoom Sessions</span> button at the top right corner on the Courses Page (click below <span className={'font-extrabold text-red dark:text-lightGrey'}>Enter Website</span>{' '}button first) to join relevant WhatsApp Groups and receive Zoom links, reminders and course materials for the sessions of these live courses.
                    </p>
                    <Spacer y={3}/>
                    <Divider/>
                    <Spacer y={3}/>

                    <h2 className={'text-lg font-bold text-red'}>
                        Pre-Requisites:
                    </h2>
                    <Spacer y={1}/>
                    <p>These sessions will be most suitable for an audience already well versed with the books of Śrīla Prabhupāda. A Bhakti-śāstrī degree will be a highly useful pre-requisite.
                    </p>
                    <Spacer y={3}/>
                    <Divider/>
                    <Spacer y={3}/>
                    <h2 className={'text-lg font-bold text-red'}>
                        The Facilitator:
                    </h2>
                    <Spacer y={1}/>
                    <p>The courses are facilitated by His Grace Balarām Śakti Dāsa, a brahmacārī from Śrī Śrī Rādhā-Gopināth temple, ISKCON Chowpatty, Mumbai, India. He is an initiated disciple of <Link
                        isExternal
                        href='https://www.radhanathswami.com/'
                        className={'inline z-50'}
                    >
                        His Holiness Rādhānātha Swāmi Mahārāja
                    </Link>.
                    </p>
                    <Spacer y={3}/>
                    <Divider/>
                    <Spacer y={3}/>
                    <h2 className={'text-lg font-bold text-red'}>
                        Charges:
                    </h2>
                    <Spacer y={1}/>
                    <p>All the courses, present and future will always remain free of charge.
                    </p>
                    <Spacer y={3}/>
                    <Divider/>
                </CardBody>
                <CardFooter className={'flex justify-end'}>
                    <Button color='primary' onClick={() => onAcceptTerms()} className={'text-lg z-50 bg-darkBlue'}>
                        Enter website
                    </Button>
                </CardFooter>
            </Card>
        </motion.div>
    );
};

export default LandingPage;
