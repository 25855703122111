import { useEffect, useRef, useState } from 'react';
import { db } from '../firebase/config';
import { collection, onSnapshot, orderBy as fireBaseOrderBy, query as fireBaseQuery } from 'firebase/firestore';
import { useBhaktiContext } from './useBhaktiContext';

export const useCollection = (collectionInfo, _orderBy = false, _field = '', _direction = 'asc') => {
    const [ documents, setDocuments ] = useState(null);
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    // const query = useRef(_query).current;
    const orderBy = useRef(_orderBy).current;
    const field = useRef(_field).current;
    const direction = useRef(_direction).current;
    const { dispatch } = useBhaktiContext();
    useEffect(() => {
        setLoading(true);
        let ref = collection(db, collectionInfo);

        // if (query) {
        //     ref = fireBaseQuery(ref, where(...query));
        // }
        if (orderBy) {
            ref = fireBaseQuery(ref, fireBaseOrderBy(field, direction));
        }
        try {
            const unSub = onSnapshot(ref, (snapshot) => {
                let results = [];
                snapshot.docs.forEach(doc => {
                    results.push({ ...doc.data(), id: doc.id });
                });

                if (results.length) {
                    dispatch({ type: 'GET_COURSES', payload: [ ...results ] });
                }
                if (!results) {
                    dispatch({ type: 'GET_COURSES', payload: [] });
                }
                // update state
                setDocuments(results);
                setLoading(false);
                setError(false);
            });

            return () => {
                unSub();
            };
        } catch (e) {
            setError(true);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ collectionInfo ]);

    return { documents, error, loading };

};