import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation, Link } from 'react-router-dom';
import MainCourses from './components/MainCourses';
import { useTheme } from 'next-themes';
import { Navbar, NavbarContent, NavbarMenuToggle, NavbarBrand, NavbarItem, NavbarMenu, NavbarMenuItem, Dropdown, DropdownTrigger, Button, DropdownMenu, DropdownItem } from '@nextui-org/react';
import { ReactComponent as MobileSP } from './images/mobileSP.svg'
import DarkMode from './components/DarkMode';
import About from './components/About';
import ContactMe from './components/ContactMe';
import { ReactComponent as MobileDarkSP } from './images/mobileDarkSP.svg'
import Schedule from './components/Schedule';
import Live from "./lottie/live.json";
import LandingPage from './components/LandingPage';
import { ChevronDown } from './components/Icons';
import BSP from './components/BSP';
import SrilaPrabhupada from './components/SrilaPrabhupada';
import Lottie from 'react-lottie';
import AnimatedTextCharacter from './components/AnimatedTextCharacter';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const { pathname } = useLocation();
    const areTermsAccepted = sessionStorage.getItem("accepted");
    useEffect(() => {
        setIsMenuOpen(false)
    }, [pathname]);
    const icons = {
        // @ts-ignore
        chevron: <ChevronDown fill='currentColor' size={16}/>,
    };
    const SPLink = '/srilaprabhupada'
    const ISKCONLink = '/bsp'
    const menuItems = [
        {
            title: 'Courses',
            link: '/courses',
            isActive: pathname.includes('courses')
        },
        {
            title: 'About',
            link: '/about',
            isActive: pathname.includes('about')
        },
        {
            title: 'Zoom Sessions',
            link: '/sessions',
            isActive: pathname.includes('sessions')
        },
        {
            title: 'Ask Questions',
            link: '/contact',
            isActive: pathname.includes('contact')
        }
    ]
    const { theme, setTheme } = useTheme();
    const navigate = useNavigate()

    useEffect(() => {
        setTheme('light')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Live,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        if (areTermsAccepted !== 'Yes') {
            navigate(`/?redirectTo=${pathname}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areTermsAccepted]);
    // const text = "Bhakti Grantha Sevā".split(" ");
    const text = "Bhakti Grantha Sevā";
    return (
        <div className={'bg-white dark:bg-black/10 min-h-screen '}>
            {areTermsAccepted === 'Yes' && <Navbar isMenuOpen={isMenuOpen}
                                                   onMenuOpenChange={setIsMenuOpen}
                                                   isBordered
                                                   maxWidth={'2xl'}
                                                   position={'sticky'}
                                                   classNames={{
                                                       item: [
                                                           "flex",
                                                           "relative",
                                                           "h-full",
                                                           "items-center",
                                                           "text-darkBlue",
                                                           "dark:text-lightGrey",
                                                           "data-[active=true]:text-lightOrange",
                                                           "dark:data-[active=true]:text-lightBlue",
                                                           "data-[active=true]:font-bold",
                                                       ],
                                                       base: [
                                                           // "bg-lightGrey"
                                                       ],
                                                       menuItem: [
                                                           "text-darkBlue",
                                                           "dark:text-lightGrey",
                                                           "data-[active=true]:text-lightOrange",
                                                           "dark:data-[active=true]:text-lightBlue",
                                                           "data-[active=true]:font-bold",
                                                       ],

                                                   }}>
                <NavbarContent data-justify={'end'}>
                    <NavbarMenuToggle
                        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                        className='sm:hidden'/>
                    <NavbarBrand>
                        <div onClick={() => navigate('/')} className={'cursor-pointer'}>
                            {theme === 'light' ? <MobileSP/> : <MobileDarkSP/>}
                        </div>
                        <Link className='ml-3 font-extrabold sm:text-4xl text-2xl text-darkBlue dark:text-lightGrey' to={'/'}>
                            {/*{text.map((el, i) => (*/}
                            {/*    <motion.span*/}
                            {/*        initial={{ opacity: 0, }}*/}
                            {/*        animate={{ opacity: 1, }}*/}
                            {/*        transition={{*/}
                            {/*            duration: 0.5,*/}
                            {/*            delay: i / 1.5,*/}
                            {/*        }}*/}
                            {/*        key={i}*/}
                            {/*    >*/}
                            {/*        {el}{" "}*/}
                            {/*    </motion.span>*/}
                            {/*))}*/}
                            <AnimatedTextCharacter text={text}/>
                        </Link>
                    </NavbarBrand>
                </NavbarContent>

                <NavbarContent className='hidden sm:flex gap-4' justify={'end'}>
                    {menuItems.map((item, index) => {
                        if (index !== 1) {
                            return (
                                <NavbarItem key={`${item}-${index}`} isActive={item.isActive}>
                                    <Link
                                        className={'font-bold text-lg'}
                                        to={item.link}
                                    >
                                        {item.title}
                                        {item.title.includes('Zoom Sessions') &&
                                            <div className={'w-7 absolute right-[-8px] top-[11px]'}>
                                                <Lottie
                                                    options={defaultOptions}
                                                />
                                            </div>}

                                    </Link>
                                </NavbarItem>
                            )
                        }
                        return (
                            <Dropdown>
                                <NavbarItem>
                                    <DropdownTrigger>
                                        <Button
                                            disableRipple
                                            className={`font-bold text-lg ${(pathname.includes(SPLink) || pathname.includes(ISKCONLink)) ? 'text-lightOrange' : 'text-darkBlue'}`}
                                            endContent={icons.chevron}
                                            radius='sm'
                                            variant='light'
                                        >
                                            About
                                        </Button>
                                    </DropdownTrigger>
                                </NavbarItem>
                                <DropdownMenu
                                    aria-label='ACME features'
                                    className='w-[340px]'
                                    itemClasses={{
                                        base: "gap-4",
                                    }}
                                >
                                    <DropdownItem
                                        key='Srila Prabhupāda'
                                        className={`font-bold text-lg ${(pathname.includes(SPLink)) ? 'text-lightOrange' : 'text-darkBlue'}`}
                                        onClick={() => navigate(SPLink)}
                                    >
                                        <Link
                                            className={'font-bold text-lg'}
                                            to={SPLink}
                                        >
                                            Śrīla Prabhupāda & Education
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem
                                        key='BSP'
                                        className={`font-bold text-lg ${(pathname.includes(ISKCONLink)) ? 'text-lightOrange' : 'text-darkBlue'}`}
                                        onClick={() => navigate(ISKCONLink)}

                                    >
                                        <Link
                                            className={'font-bold text-lg'}
                                            to={ISKCONLink}
                                        >
                                            Balarāma Śakti Dāsa
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem
                                        key='privacy'
                                        className={`font-bold text-lg ${(pathname.includes('/privacy-policy')) ? 'text-lightOrange' : 'text-darkBlue'}`}
                                        onClick={() => navigate('/privacy-policy')}

                                    >
                                        <Link
                                            className={'font-bold text-lg'}
                                            to={'/privacy-policy'}
                                        >
                                            Copyright Notice & Request
                                        </Link>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        )
                    })}
                    <NavbarItem>
                        <DarkMode/>
                    </NavbarItem>
                </NavbarContent>
                <NavbarContent justify={'end'} className={'sm:hidden'}>
                    <NavbarItem>
                        <DarkMode/>
                    </NavbarItem>
                </NavbarContent>
                <NavbarMenu>
                    {menuItems.map((item, index) => {
                        if (index !== 1) {
                            return (
                                <NavbarMenuItem key={`${item}-${index}`} isActive={item.isActive}>
                                    <Link
                                        className='w-full'
                                        to={item.link}
                                    >
                                        {item.title}
                                    </Link>
                                </NavbarMenuItem>
                            )
                        }
                        return (
                            <Dropdown>
                                <NavbarMenuItem>
                                    <DropdownTrigger>
                                        <Button
                                            disableRipple
                                            className={`font-bold text-lg ${(pathname.includes(SPLink) || pathname.includes(ISKCONLink)) ? 'text-lightOrange' : 'text-darkBlue'}`}
                                            endContent={icons.chevron}
                                            radius='sm'
                                            variant='light'
                                        >
                                            About
                                        </Button>
                                    </DropdownTrigger>
                                </NavbarMenuItem>
                                <DropdownMenu
                                    aria-label='ACME features'
                                    className='w-[340px]'
                                    itemClasses={{
                                        base: "gap-4",
                                    }}
                                >
                                    <DropdownItem
                                        key='Srila Prabhupāda'
                                        className={`font-bold text-lg ${pathname.includes(SPLink) ? 'text-lightOrange' : 'text-darkBlue'}`}
                                        onClick={() => navigate(SPLink)}
                                    >
                                        <Link
                                            className={'text-lg'}
                                            to={SPLink}
                                        >
                                            Śrīla Prabhupāda & Education
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem
                                        key='BSP'
                                        className={`font-bold text-lg ${(pathname.includes(ISKCONLink)) ? 'text-lightOrange' : 'text-darkBlue'}`}
                                        onClick={() => navigate(ISKCONLink)}

                                    >
                                        <Link
                                            className={'text-lg'}
                                            to={ISKCONLink}
                                        >
                                            Balarāma Śakti Dāsa
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem
                                        key='privacy'
                                        className={`font-bold text-lg ${(pathname.includes('/privacy-policy')) ? 'text-lightOrange' : 'text-darkBlue'}`}
                                        onClick={() => navigate('/privacy-policy')}

                                    >
                                        <Link
                                            className={'text-lg'}
                                            to={'/privacy-policy'}
                                        >
                                            Copyright Notice & Request
                                        </Link>
                                    </DropdownItem>

                                </DropdownMenu>
                            </Dropdown>
                        )

                    })}
                </NavbarMenu>
            </Navbar>}

            <Routes>
                <Route path='/courses/*' element={<MainCourses/>}/>
                <Route path='/about' element={<About/>}/>
                <Route path='/contact' element={<ContactMe/>}/>
                <Route path='/sessions' element={<Schedule/>}/>
                <Route path={ISKCONLink} element={<BSP/>}/>
                <Route path='/srilaprabhupada' element={<SrilaPrabhupada/>}/>
                <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
                <Route path='/' element={<LandingPage/>}/>
                <Route
                    path='/'
                    element={<Navigate to='/' replace/>}
                />
            </Routes>
            <div className={'fixed left-0 bottom-0 w-full text-black text-center bg-white/50 z-40 backdrop-blur-lg'}>
                <p className={'sm:text-lg text-md'}>©️All rights reserved Bhakti Grantha Sevā, 2024</p>
            </div>
        </div>
    );
}

export default App;

