import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: 'bhakti-granth-seva.firebaseapp.com',
    projectId: 'bhakti-granth-seva',
    storageBucket: 'bhakti-granth-seva.appspot.com',
    messagingSenderId: '470067627886',
    appId: '1:470067627886:web:ccf17fac791347c70b237f',
    measurementId: 'G-X82YJR0L9Y',
};
// init firebase

const app = initializeApp(firebaseConfig);
// getAnalytics(app);

// init firestore
const db = getFirestore();
getAnalytics(app);

// init storage
const projectStorage = getStorage();

export { db, projectStorage, ref, getDownloadURL, uploadBytes, uploadBytesResumable };
