import { BhaktiContext } from '../context/BhaktiContext';
import { useContext } from 'react';

export const useBhaktiContext = () => {
    const context = useContext(BhaktiContext);

    if (!context) {
        throw Error('useBhaktiContext must be used inside an BhaktiContextProvider');
    }

    return context;
};

