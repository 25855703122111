import React, { useEffect } from 'react';
import { Card, CardHeader, CardBody, Spacer, Divider, Link } from '@nextui-org/react';
import { motion } from 'framer-motion';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

const SrilaPrabhupada = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 250)

    }, [])
    return (
        <main className='pb-10 sm:pb-0'>
            <div className={'grid-rows-schedule-row grid-cols-schedule-column grid xs:h-full sm:h-screen sm:grid-cols-8 sm:mt-0'}>
                <div className={'col-span-full bg-sp brightness-50 bg-fixed bg-center xs:h-full bg-cover bg-no-repeat  sm:block hidden opacity-90 row-span-full'}/>
                <motion.div className={'col-start-2 col-end-8 xl:col-start-3 xl:col-end-7 opacity-[97%] xs:row-end-auto row-start-2 sm:row-end-span-2 h-[calc(100%_-_10rem)]'}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 0.8 }}
                            transition={{
                                duration: 0.5,
                                ease: "easeOut",
                            }}
                >
                    <Card className={'grid grid-cols-8'}>
                        <CardHeader className='pb-0 pt-2 px-4 flex-col items-center col-span-full'>
                            <h1 className='font-extrabold sm:text-3xl text-2xl mb-2 text-red dark:text-lightGrey mt-2'>On Śrīla Prabhupāda and Education</h1>
                        </CardHeader>
                        <CardBody className={'col-span-full'}>
                            <p className={'text-center'}>
                                <FaQuoteLeft className={'h-2 w-2 inline mr-1 mb-2.5'}/>To systematically propagate spiritual knowledge to society at large and to educate all people in the techniques of spiritual life in order to check the imbalance of values in life and to achieve real unity and peace in the world.<FaQuoteRight className={'h-2 w-2 inline ml-1 mb-2.5'}/>
                            </p>
                            <Spacer y={1}/>
                            <span className={'text-center italic text-lightOrange font-extrabold'}>1st of the 7 Purposes of ISKCON as drafted by His Divine Grace at ISKCON’s incorporation </span>
                            <Spacer y={2}/>
                            <Divider/>
                            <Spacer y={2}/>
                            <p>Incorporating <Link
                                isExternal
                                href='https://en.wikipedia.org/wiki/International_Society_for_Krishna_Consciousness'
                                className={'inline z-50'}
                            >
                                ISKCON
                            </Link>, His Divine Grace Śrīla Prabhupāda laid down seven purposes of our glorious institution. Of these seven, four purposes focus on education.
                            </p>
                            <Spacer y={2}/>
                            <p>
                                Śrīla Prabhupāda envisioned for devotees to develop a proper understanding of the message of the scriptures and initiated many programs like the Bhakti-Śāstrī course and exams. His Divine Grace had expressed his desire for continued education of the devotees through other courses like Bhakti-Vaibhava, Bhakti-Vedānta and Bhakti-Sārvabhauma.
                            </p>
                            <Spacer y={2}/>
                            <p>
                                Through such a committed study of Śrīla Prabhupāda’s books, which are saturated with the teachings of the previous ācāryas, it is but natural that a few devotees may develop the desire to further learn the works of the previous torch bearers of our sampradāya.
                            </p>
                            <Spacer y={2}/>
                            <Divider/>
                            <Spacer y={2}/>
                            <p>Śrīla Prabhupāda acknowledged this desire many times through his Bhaktivedānta purports, classes and conversations:</p>
                            <Spacer y={2}/>
                            <p className={'ml-20'}>
                                <FaQuoteLeft className={'h-2 w-2 inline mr-1 mb-2.5'}/>Within the past five hundred years, many erudite scholars and ācāryas like Jīva Gosvāmī, Sanātana Gosvāmī, Viśvanātha Cakravartī, Vallabhācārya, and many other distinguished scholars even after the time of Lord Caitanya made elaborate commentaries on the Bhāgavatam. And the serious student would do well to attempt to go through them to better relish the transcendental messages.<FaQuoteRight className={'h-2 w-2 inline ml-1 mb-2.5'}/>
                            </p>
                            <Spacer y={1}/>
                            <span className={'text-right italic text-lightOrange font-extrabold'}>Śrīmad-Bhāgavatam 1.1.1 Purport </span>
                            <Spacer y={2}/>
                            <p className={'ml-20'}>
                                <FaQuoteLeft className={'h-2 w-2 inline mr-1 mb-2.5'}/>It is necessary, therefore, for the serious students of Śrīmad-Bhāgavatam to follow the notes and comments of the great ācāryas like Jīva Gosvāmī and Viśvanātha Cakravartī.<FaQuoteRight className={'h-2 w-2 inline ml-1 mb-2.5'}/>
                            </p>
                            <Spacer y={1}/>
                            <span className={'text-right italic text-lightOrange font-extrabold'}>
                                Śrīmad-Bhāgavatam 3.4.28 Purport
                            </span>
                            <Spacer y={2}/>
                            <Divider/>
                            <Spacer y={2}/>
                            <p>This {' '}
                                <RouterLink className='font-extrabold text-darkBlue dark:text-skyblue' to={'/courses'}>
                                    Bhakti Grantha Sevā </RouterLink> portal is a humble attempt to contribute in a small but meaningful way to this educational vision of our beloved Founder-ācārya.
                            </p>
                            <Spacer y={2}/>
                            <p className={'mb-5'}>
                                We therefore beg the Vaiṣṇavas to bless this endeavor so that this portal can inspire devotees in their surrender to Śrīla Prabhupāda and Śrī Kṛṣṇa.
                            </p>
                        </CardBody>
                    </Card>
                </motion.div>
            </div>
        </main>
    )
};

export default SrilaPrabhupada;
