import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { NextUIProvider } from '@nextui-org/react';
import { BrowserRouter } from 'react-router-dom';
import { BhaktiContextProvider } from './context/BhaktiContext';
import { ThemeProvider as NextThemesProvider } from 'next-themes'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <NextUIProvider>
            {/*@ts-ignore*/}
            <NextThemesProvider
                attribute='class'
                defaultTheme='dark'
                themes={['light', 'dark', 'modern']}
            >
                <BhaktiContextProvider>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </BhaktiContextProvider>
            </NextThemesProvider>
        </NextUIProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
