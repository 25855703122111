import React, { useEffect } from 'react';
import ScheduleTable from './ScheduleTable';

const Schedule = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 250)

    }, [])
    return (
        <>
            <main className='pb-10 sm:pb-0'>
                <div className={'grid-rows-schedule-row grid-cols-schedule-column grid xs:h-full sm:h-screen sm:grid-cols-8 sm:mt-0'}>
                    <div className={'col-span-full bg-schedule brightness-50 bg-fixed bg-center xs:h-full bg-cover bg-no-repeat  sm:block hidden row-span-full'}>
                    </div>
                    <div className={'col-start-2 col-end-8 opacity-[97%] xs:row-end-auto row-start-2 sm:row-end-span-2 h-screen'}>
                        <p className={'sm:hidden mb-3 ml-2 text-red font-bold'}>Scroll right to see more 👉👉</p>
                        <ScheduleTable/>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Schedule;
